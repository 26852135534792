import React, { useEffect, useState, useMemo } from 'react'
import { useMediaQuery } from "react-responsive";
import '../../src/Styles.css';
import HomeDrawer from '../Components/HomeDrawer';
import AndriodBage from '../assets/google-play-badge.png'
import IosBage from '../assets/iosBage.png'


const AppsNav = () => {

  const minMax = useMediaQuery({ query: '(max-width: 900px)' })

  const [themeTextColor, setTheme] = useState(null);

  const isDark = undefined;

  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );

  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      setTheme('#fff')
    } else {
      setTheme('#111')
    }
  }, [value]);

  useEffect(() => {
    getMobileOperatingSystem()
  }, []);

  function getMobileOperatingSystem() {

    // Get the user agent string from the browser
    const userAgent = navigator.userAgent;

    // Check if the user agent string contains keywords for different operating systems
    if (userAgent.match(/Windows/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_self", "noreferrer");
      return null;
    } else if (userAgent.match(/Macintosh|Mac OS/i)) {
      window.open('https://apps.apple.com/us/app/pine-dating-for-the-caribbean/id1665001650', "_self", "noreferrer");
      return null;
    } else if (userAgent.match(/Android/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_self", "noreferrer");
      return null;
    } else if (userAgent.match(/iOS/i)) {
      window.open('https://apps.apple.com/us/app/pine-dating-for-the-caribbean/id1665001650', "_self", "noreferrer");
      return null;
    } else {
      return null
    }
  }

  return (
    <div >
      <div>
        <HomeDrawer />
      </div>

      <p className="home-logo" style={{ textAlign: 'center', fontSize: 30, marginTop: '20%', color: '#7965fc', }}>Caribe Match</p>

      <div >
        <div >
          {minMax ? <h3 style={{ textAlign: 'center', fontSize: 28, color: themeTextColor, marginTop: '20%' }}>Choose a Store</h3> : <h3 style={{ textAlign: 'center', fontSize: 30, color: themeTextColor, marginTop: '2%' }}>Choose a Store</h3>}
        </div>

        <div onClick={getMobileOperatingSystem} className='not-found-center'>

          {minMax ? <img src={AndriodBage} alt="Trulli" width="145" height="65"></img> : <img src={AndriodBage} alt="Trulli" width="155" height="75"></img>}

          {minMax ? <img src={IosBage} alt="Trulli" width="130" height="41"></img> : <img src={IosBage} alt="Trulli" width="150" height="50"></img>}

        </div>
      </div>

    </div >
  )
}

export default AppsNav