
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";


import Home from './Components/Home';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsConditions from './Components/TermsConditions';
import SafetyTips from './Components/SafetyTips';
import Advises from './Components/Advises';
import Contact from './Components/Contact';
import AppNav from './Components/AppNav'
import Track1 from './Components/TrackVists/Track1'
import Blog_1 from './Blogs/blog_1';
import Blog_2 from './Blogs/blog_2';
import Blog_3 from './Blogs/blog_3';
import Blog_4 from './Blogs/blog_4';
import Blog_5 from './Blogs/blog_5';
import Blog_6 from './Blogs/blog_6';
import Blog_7 from './Blogs/blog_7';
import Blog_8 from './Blogs/blog_8';
import Blog_9 from './Blogs/blog_9';
import Blog_10 from './Blogs/blog_10';

//import HomeDrawer from './Components/HomeDrawer';
import PageNotFound from './Components/404NotFound';
import './Styles.css';


function App() {
  
  const isDark = undefined;
  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [value]);

  return (
    <div>

      <BrowserRouter>
        <div>

          {/* <div>
          <HomeDrawer />
        </div> */}

          {/* <Link style={{ textDecoration: 'none' }} className="App-link" to="/">
      <div style={{ backgroundColor: 'green', height: 50, width: 150, borderRadius: 1, display: 'flex', alignContent: 'center' }}>
        <p style={{ padding: 10, }} >Home</p>
      </div>
    </Link> */}

          {/* <div className='blog-icon'>
          <h1 >hello</h1>
          </div> */}
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-conditions" element={<TermsConditions />} />
            <Route path="safety-tips" element={<SafetyTips />} />
            <Route path="dating-tips" element={<Advises />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="apps" element={<AppNav />} />
            <Route path="app" element={<AppNav />} />
            <Route path="platform" element={<AppNav />} />
            <Route path="open" element={<Track1 />} />
            {/* <Route path="/six/:anything" component={Contact} /> */}
            <Route path="10-astonishing-things-that-happen-when-you-meet-your-soulmate" element={<Blog_1 />} />
            <Route path="things-to-talk-about-when-on-a-date" element={<Blog_2 />} />
            <Route path="21-personality-questions-to-ask-on-a-first-date-that-give-you-a-sense-of-their-true-self" element={<Blog_3 />} />
            <Route path="how-to-tell-if-you-are-on-a-date-or-if-there-just-being-friendly" element={<Blog_4 />} />
            <Route path="how-do-you-get-your-matches-to-meet-offline" element={<Blog_5 />} />
            <Route path="how-to-end-it-politely" element={<Blog_6 />} />
            <Route path="can-you-meet-the-right-person-at-the-wrong-time" element={<Blog_7 />} />
            <Route path="writing-the-best-online-dating-profile" element={<Blog_8 />} />
            <Route path="signs-your-date-is-toxic" element={<Blog_9 />} />
            <Route path="lets-get-you-to-the-first-date-faster" element={<Blog_10 />} />
            <Route element={<PageNotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
